import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBmbqXAd94D42s-YklPWEJbHj2gPtvy3SQ",
  authDomain: "giganten-7a7bf.firebaseapp.com",
  projectId: "giganten-7a7bf",
  storageBucket: "giganten-7a7bf.appspot.com",
  messagingSenderId: "1015753009844",
  appId: "1:1015753009844:web:7c3327f5dee520990f85e1"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);