import React, { useState, useEffect } from 'react';
import { Fab, Tooltip, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import bgImage from '../assets/bg2.webp';
import LeadModal from './LeadModal';
import AdminModal from './AdminModal';

function Dashboard() {
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const auth = getAuth();

  useEffect(() => {
    const checkAdminStatus = () => {
      const user = auth.currentUser;
      if (user && user.email === 'nemanja@techcentergroup.se') {
        setIsAdmin(true);
      }
    };

    checkAdminStatus();
    const unsubscribe = auth.onAuthStateChanged(checkAdminStatus);

    return () => unsubscribe();
  }, [auth]);

  const handleOpenLeadModal = () => {
    setIsLeadModalOpen(true);
  };

  const handleCloseLeadModal = () => {
    setIsLeadModalOpen(false);
  };

  const handleOpenAdminModal = () => {
    setIsAdminModalOpen(true);
  };

  const handleCloseAdminModal = () => {
    setIsAdminModalOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div 
      className="min-h-screen flex flex-col justify-between"
      style={{ 
        backgroundColor: '#000000',
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: isMobile ? 'contain' : 'cover',
        paddingBottom: isMobile ? '100px' : '32px',
      }}
    >
      <div className="p-4 flex justify-end">
        <Tooltip title="Logga ut" placement="left">
          <Fab
            size="medium"
            onClick={handleLogout}
            sx={{
              bgcolor: 'white',
              color: 'black',
              '&:hover': {
                bgcolor: 'grey.200',
              },
            }}
          >
            <LogoutIcon />
          </Fab>
        </Tooltip>
      </div>

      <div className="flex-grow"></div>

      <div className={`p-4 flex justify-end ${isMobile ? 'pb-0' : ''}`}>
        {isAdmin && (
          <Tooltip title="Admin Panel" placement="left">
            <Fab
              size="large"
              onClick={handleOpenAdminModal}
              sx={{
                mr: 2,
                boxShadow: 3,
                bgcolor: '#4a90e2',
                color: 'white',
                '&:hover': {
                  bgcolor: '#3a78c2',
                },
              }}
            >
              <AdminPanelSettingsIcon />
            </Fab>
          </Tooltip>
        )}
        <Tooltip title="Skapa Lead" placement="left">
          <Fab
            size="large"
            onClick={handleOpenLeadModal}
            sx={{
              boxShadow: 3,
              bgcolor: '#7cc53d',
              color: 'white',
              '&:hover': {
                bgcolor: '#6ab22f',
              },
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      
      <LeadModal open={isLeadModalOpen} onClose={handleCloseLeadModal} />
      {isAdmin && <AdminModal open={isAdminModalOpen} onClose={handleCloseAdminModal} />}
    </div>
  );
}

export default Dashboard;