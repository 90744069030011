import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, Box } from '@mui/material';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import './App.css';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBmbqXAd94D42s-YklPWEJbHj2gPtvy3SQ",
  authDomain: "giganten-7a7bf.firebaseapp.com",
  projectId: "giganten-7a7bf",
  storageBucket: "giganten-7a7bf.appspot.com",
  messagingSenderId: "1015753009844",
  appId: "1:1015753009844:web:7c3327f5dee520990f85e1"
};

const theme = createTheme();

// Initialize Firebase outside of the component to avoid re-initialization
let app;
let auth;
try {
  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  console.log('Firebase initialized successfully');
} catch (error) {
  console.error('Error initializing Firebase:', error);
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    console.log('App component mounted');

    if (!auth) {
      console.error('Auth object not available');
      setAuthChecked(true);
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed', user ? 'User logged in' : 'No user');
      setIsAuthenticated(!!user);
      setAuthChecked(true);
    });

    return () => {
      console.log('Cleaning up...');
      unsubscribe();
    };
  }, []);

  console.log('Rendering App component', { authChecked, isAuthenticated });

  if (!authChecked) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
            <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
